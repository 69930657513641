.new-orders-tab {
  border: 0 !important;
  gap: 1rem;
  .new-orders-item {
    .nav-content {
      background-color: transparent;
      padding: 0.5rem 1.5rem;
      border: 1px solid #dee2e6 !important;
      border-radius: 3px;
      min-width: 180px;
      position: relative;
      text-align: center !important;

      .orders-check {
        position: absolute;
        top: 5px;
        right: 10px;
        opacity: 0;
        top: -8px;
        right: -7px;
        background: white;
        border-radius: 50%;
      }

      &.active {
        background-color: #d1f5f0 !important;
        border: 1px solid #01c7b1 !important;

        h1 {
          color: #01c7b1 !important;
        }

        .orders-check {
          opacity: 1;
        }
      }
    }
  }
}

.search {
  position: absolute;
  top: 50%;
  left: 95%;
  transform: translate(-95%, -50%);
  z-index: 10;
}

.trend.trend-upwards {
  background-image: url(../images/increase.svg);
  background-repeat: no-repeat;
  background-size: 1em;
}

.trend.trend-upwards p {
  font-weight: bold;
  margin-top: -1.5px;
  margin-left: 20px;
  color: #01c7b1;
}

.zones-bg-green {
  background: #ccf3ef;
}

.zones-bg-yellow {
  background: #ffe9cc80;
}

.zones-bg-pink {
  background: #ffd7d580;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

// .radio input[type="radio"]:checked {
//   border-color: #01c7b1 !important;
//   background-color: #01c7b1 !important;
// }

// .radio input[type="radio"] {
//   appearance: none;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   border: 1px solid #b1b1b1; /* Change this to your desired color */
//   border-radius: 50%;
//   width: 15px; /* Adjust the size as needed */
//   height: 15px; /* Adjust the size as needed */
//   outline: none;
// }

// /* Style the radio button when checked */
// .radio input[type="radio"]:checked {
//   border: 1px solid #b1b1b1 !important;
//   background-color: #01c7b1 !important;
// }

#customer-sales {
.radio input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #b1b1b1; /* Change this to your desired color */
  border-radius: 50%;
  width: 15px; /* Adjust the size as needed */
  height: 15px; /* Adjust the size as needed */
  outline: none;
}

/* Style the radio button when checked */
.radio input[type="radio"]:checked {
  border: 4px solid #01c7b1 !important;
  background-color: white !important;
}

  background-color: #f2f5f7;

  .font-weight-semibold {
    font-weight: 500;
  }

  .text-black-75 {
    color: #3d5263;
  }

  .font-size-14 {
    font-size: 14px !important;
  }

  .font-size-12 {
    font-size: 12px !important;
  }

  .full-card {
    width: 215px;
  }

  .cover-img {
    object-fit: cover;
    object-position: center;
  }

  .custom-mb {
    margin-bottom: -5px;
  }

  @media (min-width: 992px) {
    .navbar-main .navbar-nav .navbar-profile-dropdown .nav-link {
      height: 100%;
      display: -ms-flexbox;
      display: flex;
      cursor: pointer;
      position: relative;
      border-radius: 0;
      border: none;
      transition: all 0.3s;
      box-shadow: none;
      padding: 0 0.25rem 0 0.4rem;
      background-color: transparent;
    }

    .navbar-main .navbar-nav .navbar-profile-dropdown:before {
      content: "";
      position: absolute;
      left: 0;
      height: 0;
      width: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #d3e4ef;
    }
  }

  @media (max-width: 1199.98px) {
    .navbar-main .navbar-nav .nav-item.dropdown > .dropdown-menu {
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      box-shadow: none;
      background-color: #fff !important;
    }
  }

  .custom-checkbox input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
  }

  .custom-checkbox input[type="checkbox"]:checked {
    background-color: #01c7b1;
    border-color: #01c7b1;
  }

  .custom-checkbox input[type="checkbox"]:checked::after {
    content: "\2714"; /* Unicode for check mark */
    display: block;
    color: white;
    font-size: 14px;
  }

  .btn-danger-alt {
    background: #fef1f2;
    color: #b22922;
  }

  .btn-danger-alt:hover {
    background: #b22922;
    color: #fef1f2;
  }

  .published-banner {
    background: #ccf3ef;
    color: #009e96;
  }
}

.search-color-active {
  width: 28px;
  height: 28px;
  background: white;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.select-color {
  height: 46.5px;

  @media (max-width: 1200px) {
    height: 39px;
  }
}

.color-plate {
  width: 20px;
  height: 20px;
  background: #8195a6;
}
