#navbarAsideContent {
  padding-top: $navbar-fixed-top-padding;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100vh;
  width: $sidebar-width; // box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  padding-top: $navbar-fixed-top-padding;
  // transition: transform 0.3s, width 0.3s;
  transition: all .5s ease;
  overflow: hidden;
  transform: translateX(calc(-100% - 20px));
  background: #fff;
  border-right: 1px solid $navbar-main-outline-border-color;

  @include media-breakpoint-up(lg) {
    transform: translateX(0);
  }

  .nav-scroller {
    height: calc(100vh - 89px);
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px #dee2e6;
      border-radius: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 8px;
      margin: 50px 0;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      // -webkit-box-shadow: inset 0 0 6px #dee2e6;
      background-color: #acaeaf;
    }
  }

  .navbar {
    padding: 0;
  }

  .nav-item {
    position: relative;
    margin-bottom: 10px;

    .nav-item {
      margin-bottom: 0;

      &:before {
        content: "";
        position: absolute;
        left: 27px;
        top: 50%;
        transform: translateY(-50%);
        height: 95%;
        width: 4px;
        border-left: 1px dashed #d1e4ee;
        opacity: 1;
        transition: all .3s;
      }

      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 8px;
        left: 36px;
        top: 55%;
        border-top: 1px solid #d1e4ee;
      }
    }



    &.active {


      >.nav-link {

        svg #feedback path {
          fill: $link-color;
        }

        p {
          color: $link-color;
        }

        svg,
        svg .a {
          fill: $link-color;
        }

        svg path {
          stroke: $link-color;
        }

        svg rect {
          stroke: $link-color;
        }

        svg circle {
          stroke: $link-color;
        }

        svg line {
          stroke: $link-color;
        }

        svg polyline {
          stroke: $link-color;
        }

        svg polygon {
          stroke: $link-color;
        }

      }
    }

    a.active {
      border-left: 4px solid theme-color("primary");
      color: theme-color("primary");

      p {
        color: $link-color;
      }

      svg,
      svg .a {
        fill: $link-color;
      }

      svg path {
        stroke: $link-color;
      }

      svg line {
        stroke: $link-color;
      }

      svg polyline {
        stroke: $link-color;
      }

      svg polygon {
        stroke: $link-color;
      }

    }

    .dropdown-menu {
      position: relative;
      box-shadow: none;

      @include media-breakpoint-up(lg) {
        top: 0;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background: #fff;
        opacity: 0;
        transition: all 0.3s;
      }

      .dropdown-item {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 50px;
      }
    }
  }

  .nav-link {
    // height: 36px;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
    padding: 0;
    overflow: hidden;
    color: $text-color;
    width: 260px;
    display: flex;
    align-items: center;
    padding: 10px 0;


    .arrow {
      display: inline-block;
      padding: 1.5px 5px;
      color: $body-color;
      height: 8px;
    }

    &[data-toggle].collapsed:before,
    &[data-toggle].collapsed:after,
    &[data-toggle]:not(.collapsed):after,
    &[data-toggle]:not(.collapsed):before {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      left: 114px;
      border: 4px solid transparent;
      z-index: 2;
    }

    &[data-toggle="collapse"].collapsed:before,
    &[data-toggle]:not(.collapsed):before {
      top: 50%;
      margin-top: 2px;
      transform: translateY(-50%);
      border-top-color: #7e8fa4;
    }

    &[data-toggle="collapse"].collapsed:after,
    &[data-toggle]:not(.collapsed):after {
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      border-top-color: #fff;
    }

    p {
      width: 100%;
      transition: left 0.3s;
      position: absolute;
      left: 50px;
      top: 12px;
      white-space: nowrap;
      display: flex;
      align-items: center;
    }

    svg rect,
    svg circle,
    svg .a,
    svg polygon,
    svg line,
    svg polyline,
    svg path {
      transition: all 0.3s ease-in-out;


    }

    &:hover {
      text-decoration: none; // background-color: $navbar-light-active-color;

      p {
        color: $link-color;
      }

      svg,
      svg .a {
        fill: $link-color;
      }

      svg path {
        stroke: $link-color;
      }

      svg circle {
        stroke: $link-color;
      }

      svg line {
        stroke: $link-color;
      }

      svg polyline {
        stroke: $link-color;
      }

      svg rect {
        stroke: $link-color;
      }

      svg polygon {
        stroke: $link-color;
      }
    }
  }

  .nav-link-title {
    margin: 0;
    font-size: 0.9rem;
    line-height: 16px;
    position: relative;

    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }

  .nav-link-icon {
    width: 18px;
    height: 18px;
    margin-left: 20px;
    font-size: 13px;
    line-height: 13px;
    color: $text-color;
    display: block;

    svg {
      width: 100%;
      height: auto;

      &,
      .a {
        fill: $text-color;
      }
    }
  }

  &.sidebar--small {
    transform: translateX(0);
  }

  @include media-breakpoint-up(lg) {
    &.sidebar--small {
      width: $sidebar-width-collapsed;
      overflow: visible;

      .sidebar__content {
        width: $sidebar-width-collapsed;
        overflow: visible !important;
        transition: width 0.3s;
      }

      .nav-link {
        overflow: hidden;
        width: $sidebar-width-collapsed;
        background-color: transparent;

        p {
          position: absolute;
          left: 65px;
          width: 100%;
          transition: left 0.3s;
        }

        .dropdown-toggle {

          &::before,
          &::after {
            display: none;
          }
        }

        &:hover {
          width: 260px;
          background-color: theme-color("primary");
          color: #fff;

          svg,
          svg .a {
            fill: #fff;
          }

          svg path {
            stroke: #fff;
          }


          svg line {
            stroke: #fff;
          }

          svg polyline {
            stroke: #fff;
          }

          svg rect {
            stroke: #fff;
          }

          svg polygon {
            stroke: #fff;
          }

          p {
            left: 50px;
            color: #fff;
          }
        }
      }
    }
  }
}
