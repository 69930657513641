.list-group {
    &.list-group-top-items {
        margin-top: -18px;

        .list-group-item {
            padding: 18px 0;
        }

        small.font-full {
            font-size: 100%;
        }
    }
}
