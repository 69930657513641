.wrapper {
  &.wrapper--full-width {
    .container-wrap {
      padding-left: 0;

      @include media-breakpoint-up(sm) {
        padding-left: $sidebar-width-collapsed;
      }
    }
  }

  &.wrapper--no-sidebar {
    .container-wrap {
      padding-left: 0;
    }
  }
}

.container-wrap {
  padding-left: 0;
  transition: padding-left 0.3s;

  >.container {
    padding: $grid-gutter-width / 2;
  }

  @include media-breakpoint-up(lg) {
    padding-left: $sidebar-width;
    // padding-top: $navbar-fixed-top-padding;
    padding-top: 53px;

    min-height: 95vh;

    >.container {
      padding: $grid-gutter-width;
    }
  }
}


section {
  &.section {
    margin-bottom: $grid-gutter-width / 2;

    @include media-breakpoint-up(sm) {
      margin-bottom: $grid-gutter-width;
    }

    &:last-child,
    section:last-child,
    section.section:last-child .card {

      margin-bottom: 0;
    }
  }
}

.timeline-block {
  .timeline-item {
    border-left: 1px solid #dee2e4;
    position: relative;
    padding-left: 21px;
    padding-top: 3px;
    padding-bottom: 35px;
    margin-left: 10px;

    &:last-child {
      border-left-color: transparent !important;
    }

    p {
      letter-spacing: 0.5px;
      font-weight: 400 !important;
      margin-bottom: 5px;

      &.lead {
        font-weight: 600 !important;
        font-size: 1rem;
      }
    }
  }
}
