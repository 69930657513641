.badge {
  &.badge-lg {
    padding: 0.9375rem 2.5rem;
    font-size: 1rem;
    font-weight: $font-weight-bold;
  }

  &.badge-circle {
    @include border-radius(50%);
    text-align: center;
    height: 0;
    width: 32px;
    padding: 16px 0;

    &,
    > * {
      line-height: 0;
    }
  }
  &.badge-rounded {
    border-radius: 35px;
    padding: 1rem;
    background: $badge-bg-blue;
    color: #fff;
    margin-right: 5px;
  }

  &[class*="badge-outline-"] {
    background-color: transparent !important;
    border: 1px solid $slate-gray-light;
    color: $slate-gray-light;
  }
}

.badge-outline {
  border-radius: 25px;
  padding: 3px 7px;
  font-size: 12px;

  &.sm {
    padding: 2px 8px !important;
    font-size: 10px !important;
  }
  &.md {
    padding: 4px 8px !important;
    font-size: 12px !important;
  }

  &.lg {
    padding: 5px 10px !important;
    font-size: 14px !important;
  }

  &.xl {
    padding: 5px 10px !important;
    font-size: 14px !important;
  }
  &.xxl {
    padding: 7px 14px !important;
    font-size: 14px !important;
  }

  @mixin statusColor($bgColor, $textColor, $borderColor) {
    background-color: $bgColor;
    color: $textColor;
    border: 1px solid $borderColor;
  }

  &.active {
    @include statusColor(#f0fffa, #00b27c, #00b27c);
    .badge {
      background: #00b27c !important;
    }
  }

  &.pending {
    @include statusColor(#fff5ec, #cc7700, #f7961c);
    .badge {
      background: #cc7700 !important;
    }
  }

  &.danger {
    @include statusColor(#fef1f2, #b22922, #ed1b2e);
    .badge {
      background: #b22922 !important;
    }
  }

  &.disabled {
    @include statusColor(#f2f2f2, #5a6064, #e6eaed);
    .badge {
      background: #5a6064 !important;
    }
  }

  &.closed {
    @include statusColor(#f0faff, #0062cc, #0062cc);
    .badge {
      background: #0062cc !important;
    }
  }

  &.added {
    @include statusColor(#f0faff, #26576e, #26576e);
    .badge {
      background: #d7e6ed !important;
    }
  }
}
