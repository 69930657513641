.pagination {
    border-radius: 0;

    .page-item {
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }

        .page-link {
            border-style: none none solid none;
            border-color: transparent;
            color: $body-color;
        }

        &.active .page-link {
            background-color: transparent;
            color: theme-color("primary");
            border-color: theme-color("primary");
        }

        &:first-child,
        &:last-child {
            .page-link {
                border-radius: 0;
            }
        }
    }
}