// Modal Panel: Right
$header-nav-height: 0;
$modal-height: calc(100vh - #{$header-nav-height});

.modal {
  &.modal-right-pane {
    .modal-dialog {
      max-width: 600px;
      min-height: $modal-height;
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }

    .modal-header {
      border-bottom: 0;
      h4 {
        margin-top: 0.5rem;
      }
    }

    .modal-content {
      min-height: $modal-height;
      border-radius: 0;
      border: 0;
    }
  }

  &.modal-right-pane {
    .modal-dialog {
      transform: translate(100%, 0);
      margin: $header-nav-height 0 0 auto;
    }
  }

  .modal-header {
    border-bottom: 0;
    h4 {
      font-size: 16px;
      margin-bottom: 0;
      font-weight: 700;
      margin-top: 1rem;
    }
  }

  @media (min-width: 768px) {
    .modal-header {
      border-bottom: 0;
      h4 {
        font-size: 18px;
        margin-bottom: 0;
        font-weight: 700;
        margin-top: 2rem;
      }
    }
  }

  .modal-body {
    padding-top: 0;
    div > span {
      font-size: 12px;
    }
  }

  .organizations {
    .organization {
      display: block;
      color: #555;
      text-decoration: none;
      border-bottom: 1px solid $gray-200;
      padding: 21px 10px;
      &:last-child {
        border-bottom: 0;
      }
      .organization-image {
        text-align: center;

        &.col-3.col-md-3 {
          .person .person-image,
          .with-flag:before,
          [data-initials]:before {
            width: 66px;
            height: 66px;
            line-height: 66px;
            font-size: 1.4375rem;
          }

          img {
            width: 60px;
            height: 60px;
          }
        }

        .person.has-image .person-image {
          border-radius: unset;
          background: transparent;
        }
        .initials {
          text-align: center;
          padding: 15px 0;
          background: theme-color("primary");
          color: #fff;
          font-weight: bold;
          font-size: 16px;
        }
        img {
          width: 43px;
          height: 43px;
        }
        span::before {
          margin: 0;
        }
      }
      .organization-details {
        position: relative;
        h6 {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 0;
        }
        p {
          font-size: 14px;
          color: #999;
          margin-bottom: 0;
        }
        span.current {
          background: $gray-200;
          color: #999;
          padding: 6px 17px;
          position: absolute;
          bottom: 5%;
          right: 1rem;
          border: 1px solid $gray-300;
          border-radius: 20px;
        }
        span.verified {
          background: white;
          color: #1de572;
          padding: 6px 17px;
          position: absolute;
          bottom: 5%;
          right: 1rem;
          border: 1px solid #1de572;
          border-radius: 20px;
          @media (max-width: 395px) {
            padding: 3px 13px;
            bottom: -12px;
            right: 0;
          }
        }
        span.unverified {
          background: white;
          color: #f4d00e;
          padding: 6px 17px;
          position: absolute;
          bottom: 5%;
          right: 1rem;
          border: 1px solid #f4d00e;
          border-radius: 20px;
          @media (max-width: 395px) {
            padding: 3px 13px;
            bottom: -12px;
            right: 0;
          }
        }
        span.badge {
          position: absolute;
          bottom: 5%;
          right: 0rem;
          top: 0;
        }
      }
      &:hover {
        background: $gray-100;
      }
    }
  }
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    // max-width: $modal-md;
    margin: $modal-dialog-margin-y-sm-up auto;

    &.wide {
      max-width: 758px !important;
    }

    &.wider {
      max-width: 890px !important;
    }

    &.modal-xl {
      max-width: 1313px;
    }
  }
}

@media (min-width: 768px) {
  .modal-header {
    border-bottom: 0;
    h4 {
      font-size: 18px;
      margin-bottom: 0;
      font-weight: 700;
      margin-top: 1.4rem;
    }
  }

  .organization-details {
    position: relative;
    h6 {
      font-weight: 600;
      font-size: 16px !important;
      margin-bottom: 0;
    }
    p {
      font-size: 13px !important;
      color: #666;
      margin-bottom: 0;
    }
    span.current {
      background: $gray-200;
      color: #999;
      padding: 5px;
      position: absolute;
      bottom: 5%;
      right: 1rem;
      border: 1px solid $gray-300;
    }
  }
}
.modal {
  .close {
    padding: 0.625rem 1rem 0.625rem 0.625rem;
    margin: 0 0 -1rem auto;

    &button:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}

#inactiveModal {
  .modal-body {
    padding: 3.5rem 2rem;
  }
}

.modal-backdrop.show {
  opacity: 0.7 !important;
}

//custom
@media (min-width: 576px) {
  #sentMessage .modal-dialog {
    max-width: 350px;
    margin: 1.75rem auto;
  }

  #reportSent .modal-dialog {
    max-width: 335px !important;
    margin: 1.75rem auto;
  }
}

#sentMessage .modal-footer {
  padding-bottom: 1.5rem !important;
}
