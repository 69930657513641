//To enable responsive font Sizes
//1. set $enable-responsive-font-sizes: true !default;

//2. Work on your fonts by
/* Eg. .title{
  @include font-size(4rem);
  margin: 0 0 .5em;
  font-weight: 600;
} */

@media(max-width: 1200px) {
  //for headings
  h1, .h1{
  font-size: 1.5rem;
  }
  h2, .h2{
  font-size: 1.375rem;
  }
  h3, .h3{
  font-size: 1.25rem;
  }
  h4, .h4{
  font-size: 1.125rem;
  }
  h5, .h5{
  font-size: 1.0625rem;
  }
  h6, .h6, p{
  font-size: 0.95rem;
  }
}