$card-title-line-height: 1.875rem;

.card {
  margin-bottom: 10px;
  border: 1px solid #e6eaed;

  @include media-breakpoint-up(lg) {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.oops {
    height: calc(100vh - 85px);

    @include media-breakpoint-down(sm) {
      height: unset;
    }
  }

  &.hubtelCard {
    width: auto;
    cursor: pointer;
    margin-bottom: 0.8rem;

    .person .badge {
      position: absolute;
      bottom: 0;
      right: 3rem;
      z-index: 2;

      &.badge-circle {
        width: 27px;
        padding: 13px 0;
      }
    }

    .top-right {
      top: 0;
      right: 0;
    }

    &:hover {
      box-shadow: 0px 3px 25px 1px #d4e4f0;
    }
  }

  &.card-has-header {
    > .card-body,
    .card-body:first-child {
      margin-top: (-($card-spacer-x)) !important;

      @include media-breakpoint-up(sm) {
        // margin-top: (-($card-spacer-x * 2)) !important;
        margin-bottom: 0 !important;
        background: #fff;
      }
    }

    &.dashboard-custom-card {
      min-height: 350px;
    }
  }

  .card-header {
    border-bottom: transparent;

    .card-title {
      line-height: $card-title-line-height;
    }
  }

  .card-title {
    color: #000000;
    font-size: 1.15rem;
    margin-bottom: 7px;
    font-weight: $font-weight-normal;
  }

  .card-body {
    position: relative;

    ul {
      line-height: 2;
    }
  }

  .card-footer {
    background-color: $card-footer-bg;
    border-top: 1px solid #e6eaed;
  }

  @include media-breakpoint-up(sm) {
    .card-header,
    .card-body,
    .card-footer {
      padding: ($card-spacer-x * 2);
    }

    &.card-md {
      .card-header,
      .card-body,
      .card-footer {
        padding-top: ($card-spacer-x * 1.5);
        padding-bottom: ($card-spacer-x * 1.5);
      }
    }
  }

  .card-deck {
    &.no-gutters {
      margin-right: (-($card-spacer-x)) !important;
      margin-left: (-($card-spacer-x)) !important;

      @include media-breakpoint-up(sm) {
        margin-right: (-($card-spacer-x * 2)) !important;
        margin-left: (-($card-spacer-x * 2)) !important;
      }

      > .card,
      > [class*="card-"] {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
    }
  }

  .card-caption {
    color: color("slate-gray");
    font-size: $font-size-sm;
  }

  > .card-options {
    margin-top: 5px;
    margin-bottom: -10px;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      margin-top: 10px;
      margin-bottom: -20px;
    }
  }
}

.card-deck {
  &.card-deck-divided {
    > .card {
      border-width: 0;

      &::before {
        content: "";
        position: absolute;
        left: auto;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: $card-border-color;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }

      @include media-breakpoint-up(sm) {
        .card-body {
          padding-top: theme-spacer(3);
          padding-bottom: theme-spacer(3);
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          height: 100%;
          width: 1px;
          background-color: $card-border-color;
        }

        &:last-child::before {
          display: block;
        }

        &:first-child {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

.card-stats {
  .card-title {
    font-weight: $font-weight-light;
    font-size: 1rem;
    color: #364052;
  }

  .card-text {
    font-size: 2.5rem;
  }

  .dropdown-link {
    .btn {
      background-color: transparent;
      color: color("slate-gray");

      &:focus,
      &.focus {
        outline: 0;
        box-shadow: none;
      }
    }
  }
}

.card-filters,
.card-search {
  margin-top: theme-spacer(2);

  @include media-breakpoint-up(sm) {
    margin-top: 0;
  }
}

.card-search {
  &,
  form {
    position: relative;
  }

  .form-control {
    padding-left: 10px !important;
    padding-right: 50px;

    @include media-breakpoint-up(sm) {
      width: 180px;
    }

    &:focus {
      + .btn {
        color: theme-color("primary");
      }
    }
  }

  .btn {
    position: absolute;
    right: 0;
    color: color("slate-gray");
    text-decoration: none;

    &::after {
      content: "";
      height: 29px;
      width: 1px;
      position: absolute;
      left: 2px;
      top: 8px;
      background: #e0eff9;
    }
  }
}

.card-profile {
  .profile-title {
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 30px;

    span,
    small {
      font-size: 1rem;
      font-weight: $font-weight-light;
      display: block;
      margin-top: 20px;
    }
  }

  h1.profile-title {
    font-size: 1.625rem;
    font-weight: $font-weight-bold;
  }

  h5.profile-title {
    span,
    small {
      margin-top: 15px;
    }
  }

  .person {
    display: inline-block;
    position: relative;

    .person-image {
      margin-right: 0;
    }

    .badge {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
    }
  }

  .badge {
    font-weight: $font-weight-light;
  }
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.stream--image {
  display: flex;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 24px;
  flex-direction: column;
  margin-bottom: 31px;

  .icon {
    img {
      width: 40px;
      margin: 0 20px 0 0;
    }
  }

  .info {
    flex-grow: 1;
  }

  .content {
    padding-left: 60px;

    @media (max-width: 768px) {
      padding-left: 0px;
    }

    @media (max-width: 576px) {
      margin: 0 -20px;
    }

    img {
      width: 100%;
      border-radius: 10px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06),
        3px 3px 5px 0px rgba(0, 0, 0, 0.33);
    }
  }
}

.stream--gift {
  display: flex;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 24px;
  flex-direction: column;
  margin-bottom: 31px;
  position: relative;

  .icon {
    img {
      width: 40px;
      margin: 0 20px 0 0;
    }
  }

  .info {
    flex-grow: 1;
  }

  .content {
    padding-left: 60px;
    position: relative;

    @media (max-width: 768px) {
      padding-left: 0px;
    }

    @media (max-width: 576px) {
      margin: 0 -20px;
    }

    .text {
      position: relative;

      .inner {
        bottom: 30px;
        position: absolute;
      }
    }

    img {
      width: auto;
      height: 300px;
      object-fit: fill;
      border-radius: 10px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06),
        3px 3px 5px 0px rgba(0, 0, 0, 0.33);

      @media (max-width: 576px) {
        width: 100%;
        height: auto;
      }
    }
  }
}

.stream--text {
  display: flex;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 24px;
  flex-direction: column;
  margin-bottom: 31px;

  .icon {
    img {
      width: 40px;
      margin: 0 20px 0 0;
    }
  }

  .info {
    flex-grow: 1;
  }

  .content {
    padding-left: 60px;

    @media (max-width: 768px) {
      padding-left: 0px;
    }

    img {
      width: 100%;
      border-radius: 10px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06),
        3px 3px 5px 0px rgba(0, 0, 0, 0.33);
    }
  }
}

.stream--share {
  display: flex;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 24px;
  flex-direction: column;
  margin-bottom: 31px;

  .icon {
    img {
      width: 40px;
      margin: 0 20px 0 0;
    }
  }

  .info {
    flex-grow: 1;
  }

  .content {
    padding-left: 60px;

    @media (max-width: 768px) {
      padding-left: 0px;
    }

    img {
    }
  }
}

.stream--orders {
  display: flex;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 24px;
  flex-direction: column;
  margin-bottom: 31px;

  .icon {
    img {
      // width: px;
      margin: 0 20px 0 0;
    }
  }

  .info {
    flex-grow: 1;
    font-size: 13px;
  }

  .content {
    padding-left: 60px;
  }
}

.dashboard .card .card-header .card-title {
  line-height: 2.875rem;
  font-weight: 300;
  font-size: 1.15rem;
}

// // Container
// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 1267px,
//   xl: 1267px
// );

@media (max-width: 1200px) {
  .card-text,
  h5 .card-title {
    font-size: 1.25rem !important;
  }
  .card-search .btn:after {
    top: 1px;
  }
}

.status-badge {
  padding: 1px 10px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: normal;
  display: inline-block;
  text-align: center;

  &.badge-success {
    background-color: #f0fffa;
    color: #00b27c;
    border: 1px solid #00b27c;
  }

  &.badge-danger {
    background-color: #dc3545;
    color: white;
  }

  &.badge-warning {
    background-color: #fff5ec;
    color: #f7961c;
    border: 1px solid #f7961c;
  }
}

.callout-cards {
  padding: 20px 5px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e1dfdf;
  border-left-width: 0.45rem;
  min-width: 280px;

  .badge {
    color: white;
  }

  &.confirm {
    border-left-color: #7978de;

    .badge {
      background-color: #7978de;
    }
  }

  &.accept {
    border-left-color: #40b2dd;

    .badge {
      background-color: #40b2dd;
    }
  }

  &.pickup {
    border-left-color: #ffcc00;

    .badge {
      background-color: #ffcc00;
    }
  }

  &.delivery {
    border-left-color: #007aff;

    .badge {
      background-color: #007aff;
    }
  }

  &.orders {
    border-left-color: #ff3b30;

    .badge {
      background-color: #ff3b30;
    }
  }
}

.new-callout-cards {
  padding: 10px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  border: 1px solid #e1dfdf;
  border-left-width: 0.45rem;
  min-width: 280px;
  font-size: 16px !important;

  .badge {
    color: black;
    font-weight: bold;
  }

  &.confirm {
    border-left-color: #fecc2f;

    .badge {
      background-color: #f2f5f7;
      padding: 7px 8px;
      border-radius: 8px;
    }
  }

  &.accept {
    border-left-color: #0062cc;

    .badge {
      background-color: #f2f5f7;
      padding: 7px 8px;
      border-radius: 8px;
    }
  }

  &.pickup {
    border-left-color: #008059;

    .badge {
      background-color: #f2f5f7;
      padding: 7px 8px;
      border-radius: 8px;
    }
  }

  &.delivery {
    border-left-color: #995a00;

    .badge {
      background-color: #f2f5f7;
      padding: 7px 8px;
      border-radius: 8px;
    }
  }

  &.new {
    border-left-color: #ed1b2e;

    .badge {
      background-color: #f2f5f7;
      padding: 7px 8px;
      border-radius: 8px;
    }
  }

  &.ongoing {
    border-left-color: #f7961c;

    .badge {
      background-color: #f2f5f7;
      padding: 7px 8px;
      border-radius: 8px;
    }
  }

  &.closed {
    border-left-color: #01c7b1;

    .badge {
      background-color: #f2f5f7;
      padding: 7px 8px;
      border-radius: 8px;
    }
  }

  &.orders {
    border-left-color: #cc2444;

    .badge {
      background-color: #f2f5f7;
      padding: 7px 8px;
      border-radius: 8px;
    }
  }
}

.acc {
  width: 300px;

  .count-num {
    background-color: #fff !important;
    color: black !important;
    padding: 2px 8px !important;
    border-radius: 8px !important;
  }
}
