.dropdown {
    .dropdown-toggle {
        position: relative;

        &.btn-sm {
            padding-right: 30px;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            right: 15px;
            border-width: 4px 4px;
            border-style: solid;
            border-color: transparent;
            z-index: 2;
        }

        &.card-title{
            &::before,
            &::after {
                top: 57%;
            }

            &#customerdropdown{
                &::before,
                &::after {
                    top: 47%;
                }
    
             
    
            }


        }

        &.btn-primary{

            &::before {
                border-top-color: $white;
            }

            &::after {
                border-top-color: $primary;
            }

        }

        &::before {
            top: 50%;
            margin-top: 2px;
            transform: translateY(-50%);
            border-top-color: #7E8FA4;

        }

        &::after {
            top: 50%;
            margin-top: 0;
            transform: translateY(-50%);
            border-top-color: #fff;
        }
    }

    &.dropdown-link {
        .dropdown-menu {
            position: relative;
             z-index: 1050;
            &::before,
            &::after {
                content: "";
                position: absolute;
                display: block;
                width: 0;
                height: 0;
                border-width: 7px 8px;
                border-style: solid;
                border-color: transparent;
                z-index: 1001;
            }
            &::before {
                position: absolute;
                top: -13px;
                right: auto;
                display: inline-block;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #ccc;
                border-left: 7px solid transparent;
                border-bottom-color: rgba(0, 0, 0, .1);
                content: '';
            }
            &::after {
                position: absolute;
                top: -12px;
                right: auto;
                display: inline-block;
                border-right: 6px solid transparent;
                border-bottom: 6px solid #fff;
                border-left: 6px solid transparent;
                content: '';
            }

            &.dropdown-menu-right {
                &::before {
                    right: 13px;
                }

                &::after {
                    right: 14px;
                }
            }
        }
    }

    .dropdown-menu {
        padding: 0;
        border: 0;
        font-size:0.9rem;
        box-shadow: 0 3px 25px 1px #d4e4f0;
        z-index: 1050;

        a{
            text-decoration: unset;

            &:hover{
                background-color: #f8f9fa;
            }
        }
    }

    >button {
        padding-right: 45px;
    }
}


.dropdown-menu {
    box-shadow: 0 3px 25px 1px #d4e4f0;
     z-index: 1050;
}

.dropdown .rules-toggle:after {
    border-top-color: #E6ECF0;
}