.btn-secondary {
  border-color: #f0f1f5;
  color: $slate-gray;
}

.btn {
  text-transform: uppercase !important;
  font-weight: bold;
  font-size: 13px;
  -webkit-appearance: none;
  border-radius: 8px !important;
}

.btn-social {
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px !important;
  text-decoration: none;
  transition: background 0.1s ease-out 0s,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  outline: none !important;
  background: rgb(255, 255, 255) !important;
  padding: 1.025rem 1rem;
}

// a[href]{
//   text-transform: uppercase !important;
// }

.btn.disabled,
.btn:disabled {
  opacity: 0.35;
  cursor: no-drop;
}

.btn-tag {
  color: #5c7889;
  border: 1px solid #d3e4ef;
  border-radius: 5px;
  margin: 0 0.5rem 0.5rem 0;
  font-size: 0.9rem;
  padding: 0.5rem;

  &:hover {
    color: #5c7889;
    background: #f6f8f8;
  }
}

.nav-tabs {
  .nav-link {
    color: $text-color;
    -webkit-appearance: none;

    @include hover-focus {
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-tabs-link-active-color;
    background-color: $nav-tabs-link-active-bg;
    border-color: $nav-tabs-link-active-border-color;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      border-radius: 90px 90px 0px 0px;
      width: 100%;
      height: 2px;
      background-color: theme-color("primary");
    }
  }
}

.btn-primary.disabled,
.btn-primary:disabled {
  cursor: not-allowed;
}

.btn-group-toggle {
  &.bundles {
    .btn-account-box {
      height: unset;
      padding: 17px 14px;
      margin-bottom: 10px;
    }
  }

  .btn-account-box {
    background: transparent;
    border: 1px solid #d3e4ef;
    padding: 17px 25px;
    text-align: left;
    line-height: 30px;
    width: 100%;
    height: 125px;
    white-space: unset !important;

    i {
      position: absolute;
      font-size: 20px;
      right: 0;
      top: -6px;
      color: #009d97;
      opacity: 0;
      background-color: #fff;
      -webkit-transition: opacity 0.2s ease-in-out;
      -moz-transition: opacity 0.2s ease-in-out;
      -ms-transition: opacity 0.2s ease-in-out;
      -o-transition: opacity 0.2s ease-in-out;
      transition: opacity 0.2s ease-in-out;
    }

    &.service-box {
      width: 339px;

      @include media-breakpoint-down(sm) {
        width: 306px;
      }

      > div {
        > div:nth-of-type(2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      img {
        height: 80px;
        object-fit: contain;
      }

      h6 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &.active {
      border: 2px solid $primary;

      i {
        opacity: 1;
      }
    }

    span {
      line-height: 23px;
      display: block;
    }
  }
}

//Radio buttons

.btn-outline {
  color: black;
  background-color: transparent;
  background-image: none;
  border-color: #d3e4ef;
  position: relative;
  border-radius: 4px;

  i {
    color: $primary;
    position: absolute;
    display: none;
    background: #fff;
  }

  &.active {
    color: $primary;
    border-color: $primary;

    i {
      display: block;
      color: #01c7b1;
      top: 20px;
      right: -8px;
      // position: absolute;
    }
  }
}

@media (max-width: 1200px) {
  //for buttons
  .btn {
    padding: 0.38rem 0.5rem;
    font-size: 0.875rem;
  }
}

@media (max-width: 768px) {
  .btn-account-box {
    padding: 5px 14px !important;
    text-align: left;
    line-height: 20px;
  }
}
