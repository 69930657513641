/*--------------
    Blank State
---------------*/
.blank-state {
  text-align: center;
  padding: 1em 0 0;
  color: color("slate-gray");
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.full {
    padding: 0 !important;
    height: calc(100vh - 345px);
  }

  .blank-state-message {
    color: $body-color;
    margin: 0 auto 0.25rem;
    line-height: 1.5em;
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
    max-width: 500px;

    &::before {
      content: "";
      display: block;
      position: relative !important;
      top: auto;
      left: auto;
      line-height: 1em;
      margin: 0 auto theme-spacer(3);
      width: 180px;
      height: 50px;
      background-image: url(../images/icons/no-data.svg);
      background-repeat: no-repeat;
      background-size: 200px 60px;
      opacity: 0.45;
    }
  }

  @include media-breakpoint-up(sm) {
    .blank-state-message {
  font-size: 1.25rem;
    }
  }

  &.blank-state-no-search-data .blank-state-message::before {
    background-image: url(../images/icons/no-search-data.svg) !important;
  }

  &.no-image .blank-state-message::before {
    display: none;
  }
}

/*--------------
    Person: Name with Image or Initials
---------------*/
.person .person-image,
[data-initials]::before,
.with-flag::before {
  content: attr(data-initials);
  display: inline-block;
  font-size: 0.9375rem;
  width: 43px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  background: #4fc3f7;
  vertical-align: middle;
  color: white;
}

.person .person-image,
[data-initials]::before {
  margin-right: 1em;
  border-radius: 50%;
}

.person {
  img {
    display: none;
  }

  &.has-image {
    position: relative;
    display: inline-block;

    .person-image {
      position: relative;
      overflow: hidden;

      img {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);
        width: 100%;
        height: auto;
      }
    }
  }
}

// Circle with Image or Initials
[data-initials]::before {
  .has-image & {
    display: none;
  }
}

// Size Variations
.person-image.person-image-md {
  @include inintial_size(64px, 1.5rem);
}

.person-image.person-image-xmd {
  @include inintial_size(96px, 1.625rem);
}

.person-image.person-image-lg {
  @include inintial_size(128px, 1.875rem);
}

.person-image.person-image-xlg {
  @include inintial_size(137px, 1.875rem);
}

.person-image.person-image-xxlg {
  @include inintial_size(256px, 1.875rem);
}

/*--------------
    Table: Flag
---------------*/
.with-flag::before {
  content: "";
  height: 11px;
  width: 16px;
  line-height: 11px;
  background-image: url(../images/flags/gh.svg);
  background-repeat: no-repeat;
  border-radius: 0;
  margin-right: theme-spacer(2);
  vertical-align: inherit;
}


.bg-black {
  background-color: black!important;
}


//remove bg from image

.mix-blend{
  mix-blend-mode: multiply;
}





$objectLineheight: 0,
0.5,
1,
1.5,
2,
2.5,
inherit,
initial,
normal,
revert,
unset;

@each $tuple in $objectLineheight {
    .line-height-#{($tuple)} {
        line-height: $tuple;      
    }
}  


$objectZindex: -1,
0,
1,
2,
auto,
inherit,
initial,
normal,
revert,
unset; 

@each $tuple in $objectZindex {
  .z-index-#{($tuple)} {
    z-index: $tuple;
  }
}










$objectConfig: 0,
contain,
cover,
fill,
scale-down;

@each $tuple in $objectConfig {
    .object-fit-#{($tuple)} {
        object-fit: $tuple;
    }
}


@for $i from 1 through 500 {
  .vh-height-#{$i} {

    height: calc(100vh - #{$i}px);
  }
}


@for $i from 0 through 20 {
  .rounded-#{$i} {

    border-radius: calc(0.25 * #{$i}rem);
  }
}



$object: (contain:contain, cover: cover, fill: fill, scale-down: scale-down);

@each $label,
$value in $object {
  .object-fit-#{$label} {
    object-fit: $value;
  }
}



/*--------------
    Legend
---------------*/
$legend: (success: #1BBF00, failed: #FF5858, warning: #FFCD00, danger: #ED1B2E, temporary:#F7961C);

.legend {
  position: relative;
  padding-left: 20px;

  &::before {
    content: "";
    width: 9px;
    height: 9px;
    display: block;
    position: absolute;
    background-color: color("gray");
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 35px;
  }
}

@each $label,
$value in $legend {
  .legend-#{$label}::before {
    background-color: $value;
  }
}


// css example
span {
  content: "\2713";
}



/*--------------
    Statuses
---------------*/
// $legend: (success: '0,204,142', failed: '255, 59, 48', pending: '255, 204, 0');

.status {
  position: relative;
  display: inline-flex;
  font-weight: 400;
  color: #364052;
  text-align: center;
  vertical-align: middle;
  padding: .3rem 1rem;
  border-radius: 2px;
  align-items: center;
  font-size: 13px;

  $icons: ('success': ('color': rgb(0, 204, 142),
        'background': rgba(0, 204, 142, 0.3),
        'icon': "\f058",
        'label': 'Success',
        'name': 'success'

      ),
      'failed': ('color': rgb(255, 59, 48),
        'background': rgba(255, 59, 48, 0.3),
        'icon': "\f057",
        'label': 'Failed',
        'name': 'failed'
      ),
      'pending': ('color': rgb(255, 204, 0),
        'background': rgba(255, 204, 0, 0.3),
        'icon': "\f056",
        'label': 'Pending',
        'name': 'pending'
      ),
      'none': ('color': #999,
        'background': rgba(133, 133, 133, 0.3),
        'icon': "\f056",
        'label': 'None',
        'name': 'none'
      ));




  @each $label,
  $value in $icons {
    &.status-#{$label} {
      background-color: map-get($value, 'background');
      color: map-get($value, 'color');

      &.alt {
        &::after {
          content: " ";
        }
      }

      &::after {
        content: map-get($value, 'label');
        color: map-get($value, 'color');
      }

      span {
        font-family: Font Awesome\ 5 Free;
        -webkit-font-smoothing: antialiased;
        height: 9px;
        width: 9px;
        background:white;
        color:     map-get($value, 'color');
        font-size: 13px;
        font-weight: bold;
        border-radius: 50%;
        line-height: 13px;
        display: inline-block;
        margin-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &::before {
          content: map-get($value, 'icon');
          position: absolute;
          // top: 0px;
        }
      }
    }
  }
}

/*--------------
    Order Badges
---------------*/
$badges: (
          blue:(
            'background': #edf8fc,
            'color': #40b2dd,
          ),
          yellow:(
            'background': #fffbeb,
            'color': #edbf06,
          ),
          purple:(
            'background': #ededff,
            'color': #7978de,
          ),
          red:(
            'background': #fdd7d5,
            'color': #ff3b30,
          ),
          green:(
            'background': #dbffdb,
            'color': #088308,
          ),
          light-green:(
            'background': #D9F8F5,
            'color': #01C7B2,
          ),
          dark-blue:(
            'background': #f0f7ff,
            'color': #007aff,
          ),
          grey:(
            'background': #f2f2f2,
            'color': #b6c1ca,
          )
        );

.order{
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 28px;

  &.tiny {
    padding: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }


  @each $color,
  $value in $badges {
    &.#{$color}{
      background-color: map-get($value, 'background');
      color: map-get($value, 'color');
    }
  }
}




/*--------------
    Rating
---------------*/

.rating {
  display: flex;
  align-items: center;

  i {
    margin-right: 10px;
    font-size: 27px;
    border-radius: 50%;
  }

  &:hover {

    i {
      background: #FFCD00;
    }
  }
}


tr:hover {
  .rating {
    i {
      background: #FFCD00;
    }
  }
}


/*--------------
    Item
---------------*/
.item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 1em 0em;
  width: 100%;
  min-height: 0px;
  background: transparent;
  padding: 0em;
  border: none;
  border-radius: 0rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: -webkit-box-shadow 0.1s ease;
  transition: -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, -webkit-box-shadow 0.1s ease;
  z-index: '';

  a {
    cursor: pointer;
  }
}

.item-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaebed; 
  padding: 8px 0;
  margin-bottom: 20px;
  position: relative;

  &::after {
    content: "";
    height: 3px;
    width: 30px;
    background: #01c7b1;
    position: absolute;
    bottom: -2px;
    left: 0;
    border-radius: 17px;
  }

  .login {
    color: #9cabb8;
    font-size: 14px;
    font-weight: bold;
  }
}




.transaction {
  content: "";
  // width: 10px;
  // height: 22px;
  display: block;
  padding-left: 16px;
  // position: absolute;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: center;
  line-height: normal;

  &.bank_deposit {

    background-image: url("../images/deposit.png");


  }

  &.bank_credit {
    background-image: url("../images/credit.png");
  }


  &.addstock {
    background-image: url("../images/up1.png");
  }

  &.reducestock {
    background-image: url("../images/down.png");
  }



}

// Images
.item>.image {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: block;
  float: none;
  margin: 0em;
  padding: 0em;
  max-height: '';
  -ms-flex-item-align: top;
  align-self: top;
}

.item>.image>img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 0.125rem;
  border: none;
}

.item>.image:only-child>img {
  border-radius: 0rem;
}

// Content
.item>.content {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  background: none;
  margin: 0em;
  padding: 0em;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 1em;
  border: none;
  border-radius: 0em;
}

.item>.content:after {
  display: block;
  content: ' ';
  height: 0px;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

.item>.image+.content {
  min-width: 0;
  width: auto;
  display: block;
  margin-left: 0em;
  -ms-flex-item-align: top;
  align-self: top;
  padding-left: 1.5em;
}

.item>.content>.header {
  display: inline-block;
  margin: -0.21425em 0em 0em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1.28571429em;
}

// Content Image
.item .content img {
  -ms-flex-item-align: middle;
  align-self: middle;
  width: '';
}

.item img.avatar,
.item .avatar img {
  width: '';
  height: '';
  border-radius: 500rem;
}

// Meta
.item .meta {
  margin: 0.5em 0em 0.5em;
  font-size: 1em;
  line-height: 1em;
  color: rgba(0, 0, 0, 0.6);
  max-width: 558px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item .meta * {
  margin-right: 0.3em;
}

.item .meta :last-child {
  margin-right: 0em;
}

.item .meta [class*="right floated"] {
  margin-right: 0em;
  margin-left: 0.3em;
}

$item-image-size: (xs: 43px, xsm: 64px, sm: 80px, md: 92px);

@each $label,
$value in $item-image-size {

  .#{$label}.image,
  .#{$label}.images .image,
  .#{$label}.images img,
  .#{$label}.images svg {
    width: $value;
    height: auto;
    font-size: .85714286rem;
  }
}

/*--------------
    Beam
---------------*/
.beam {
  width: 28px;
  height: 28px;
  display: block;
  position: absolute;
  left: -15px;
  top: 0;
  background-color: #f7f8fb;
  border-radius: 50%;
  border: 2px solid #fff;

  &::after {
    content: "";
    width: 11px;
    height: 11px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #e2e2e2;
    border-radius: 50%;
  }
}

$beam-variations: (success: #7cccca, failed: #FF7C7C);

@each $label,
$color in $beam-variations {
  .beam-#{$label} {
    background-color: lighten($color, 15%) !important;

    &::after {
      background-color: $color !important;
    }
  }
}


/*--------------
    Dots
---------------*/
.dot {
  display: inline-block;
  border-radius: 50%;
  width: 50%;
  background-color: $secondary;
  height: 15px;
  width: 15px;
  //   margin: 0 2px;
  text-align: center;

  &.dot-online {
    background-color: $primary;
  }

  &.dot-offline {
    background-color: $secondary;
  }

  &.dot-danger {
    background-color: $danger;
  }

  &.dot-sm {
    height: 10px;
    width: 10px;
  }

  &.dot-lg {
    margin: 0 4px;
    height: 20px;
    width: 20px;
  }
}



.sticky-reciept {
  padding: 10px 25px;
  position: -webkit-sticky;
  position: sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  top: 0px;
  z-index: 1;
  background: white;

  @include media-breakpoint-down(sm) {
    padding: 10px 0px;
  }

}

.chat-date {
  position: relative;
  background: #d3e3ef;
  margin: 36px 0;
  height: 1px;
  display: flex;
  justify-content: center;

  span {
    position: absolute;
    background: white;
    padding: 2px 10px;
    top: -11px;
    color: #383b3e;
  }
}

.input-counter {
  position: relative;
  min-width: 300px;


  .inner {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 10px;
  }
}


@for $i from 1 through 5000 {
  .vh-height-#{$i} {
      height: calc(100vh - #{$i}px);
  }
}

@for $x from 1 through 10 {
  .text-truncate-#{$x} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $x; /* number of lines to show */
    line-clamp:$x;
    // height: calc(100vh - #{$i}px);
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
  } 
}

