#developer {
  $primary: #000;
  $secondary: #01c7b1;
  $secondary-alt: #2b6d87;
  background-color: #ffffff;
  font-family: "Nunito", sans-serif;

  *:focus {
    outline: none !important;
  }
  
  a, a:hover, a:active, a:focus, .dropdown-toggle{
  outline: none !important;
  box-shadow: none !important;
  }

  .vl {
    border-left: 2px solid #9cabb8;
    border-radius: 5px;
  }
  nav {
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;

    &.navbar{
      height: 67px;

      .navbar-nav{
        width: 100%;
      }

      .dropdown{
        .dropdown-toggle{
          &::after{
            border-top-color: #000 !important;
            top: 54% !important;
            margin-right: 0.255em;
            right: 0 !important;


            @media (max-width: 567px){
              right: 230px !important;
            }

            @media (max-width: 768px){
              right: -15px !important;
            }

          }

          &::before{
            content: none;
          }
        }
      }
      .navbar-collapse {
        @media (max-width: 992px){
          position: fixed;
          z-index: -1;
          top: 43px;
          left: 0;
          width: 100%;
          padding: 13px;
          background: white;
        }
        
        .nav-item{
          height: 67px;
          

          @media (min-width: 768px){
            display: flex;
            align-items: center;
          }

          &:hover, &.active{
            border-bottom: 2px solid #01c7b1;
            background-color: #ecfffd;

            @media (max-width: 992px){
              border-bottom: unset;
              background-color: unset;

              .nav-link{
                font-weight: bold;
              }
            }
          }
          @media (max-width: 992px){
            height: unset;
            border-bottom: 1px solid #f2f3f4 !important;

            &:last-child {
              border-bottom: none !important;
            }
          }

          .nav-link{
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
          }

          
        }
        
      }

      
    } 
  }

  .bg-yellow {
    background-color: #fff4cc !important;
  }

  .bg-orange {
    background-color: #f7961c !important;
    opacity: 0.2;
  }

  .bg-light {
    background-color: #ffffff !important;
  }

  .bg-blue-alt {
    background-color: #e9f3ff !important;
  }

  .bg-secondary {
    background-color: $secondary !important;
  }

  .bg-light-alt {
    background-color: #f7f7fb;
  }

  .bg-developers {
    background-image: url("../../../app/dist/images/Hero Section.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    overflow: hidden;
    padding-top: 67px;
    transition: all 0.5s ease;

    > div {
      padding: 90px 0;
    }

    @media (max-width: 830px) {
      background-image: url("../../../app/dist/images/dev-mobile-bg.png");
      height: 100%;

      > div {
        padding: 70px 0;
      }
    }
  }

  .container {
    max-width: 1340px !important;
    padding: 0 15px;
  }

  .card {
    border-radius: 1rem;
  }

  .integrate {
    border-radius: 1.5rem;
  }

  .why {
    border-radius: 1rem;
  }

  .invert {
    position: fixed;
    top: 100%;
    transform: translateY(-100%);
    width: 100%;
    z-index: 100;
  }

  .section {
    padding: 100px 0;
  }

  .bg-developer {
    background-color: $secondary !important;
    padding-top: 60px;

    .container {
      padding: 70px 0;

      img {
        width: 400px;

        @media (max-width: 578px) {
          width: 100%;
        }
      }
    }
  }

  .sections {
    background: #dbf7e0;
    padding: 100px 0;
  }

  footer {
    font-size: 0.85rem;
    padding: 74px 0 25px;
    color: #000;

    @media (max-width: 991px) {
      padding: 44px 0 44px 0;
    }
  }

  li a {
    color: #000;
  }

  // .hor-line {
  //   width: 100%;
  //   height: 1px;
  //   background: #f7961c;
  //   margin-top: 40px;
  // }

  //buttons
  .btn {
    padding: 0.625rem 1rem;
    border-radius: 8px;
  }

  .btn-primary {
    color: #fff;
    background-color: #01c7b1;
    border-color: #01c7b1;
  }

  .btn-outline-primary {
    color: #01c7b1;
    border-color: #01c7b1;

    &:hover {
      background-color: #01c7b1;
      color: #fff;
    }
  }

  .btn-secondary {
    color: #fff;
    background-color: $secondary;
    border-color: $secondary;
  }

  .btn-outline-secondary {
    color: $secondary;
    border-color: $secondary;

    &:hover {
      background-color: $secondary;
      color: #fff;
    }
  }

  .text-primary {
    color: $primary !important;
  }

  .text-secondary {
    color: $secondary !important;
  }

  .text-secondary-alt {
    color: $secondary-alt !important;
  }

  footer {
    ul {
      list-style: none;
    }
  }
}

#developer-portal {
  $primary: #226666;
  $secondary: #082e4d;
  $secondary-alt: #2b6d87;
  background-color: #ffffff;

  .text-warning {
    color: #cc771c !important;
  }

  .container-wrap {
    padding-top: 0;

    .navbar-main {
      position: unset;
      height: 78px;

      i.fa-arrow-left,
      i.fa-arrow-right {
        @media (min-width: 992px) {
          font-size: 2em;
        }
      }

      .topbar__button-icon {
        fill: #000000;
      }

      .topbar__button {
        display: flex !important;
      }

      #dropdownMenuButton {
        @media (max-width: 576px) {
          padding-right: 12px;

          &::before {
            right: 0px;
          }
        }

        img {
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
  }

  //sidebar
  #navbarAsideContent {
    padding-top: 3px;
    background: #082f4d;

    @media (min-width: 992px) {
      &.sidebar--small {
        .home-item {
          .nav-link {
            &:hover {
              background-color: unset !important;
              width: auto !important;
            }

            p {
              left: 65px !important;
            }
          }
        }
        .nav-link {
          &:hover {
            background-color: #1b5480;
          }
        }

        .settings {
          transition: all 0.3s;

          .time {
            display: none;
            transition: opacity 1s ease-out;
            opacity: 0;
          }

          .icon {
            transition: all 0.3s;
          }
        }
      }
    }

    @media (max-width: 576px) {
      overflow: unset;
    }

    .settings {
      border-top: 1px solid #1b5480;

      .time {
        transition: all 0.3s;
      }
    }

    .nav-scroller {
      height: calc(100vh - 41px);
      overflow-x: unset;
      overflow-y: unset;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .nav-link {
      color: #fff;
      padding: 20px 0;

      .nav-link-icon {
        color: #fff;

        svg,
        .a {
          color: #fff;
          fill: #fff;
        }
      }

      p {
        color: #ffffff;
        top: 21px;
      }
    }

    li.nav-item {
      margin-bottom: 0;

      &.active,
      &:hover {
        svg,
        svg .a {
          fill: #fff;
        }

        svg path {
          stroke: #fff;
        }

        svg rect {
          stroke: #fff;
        }

        svg circle {
          stroke: #fff;
        }

        svg line {
          stroke: #fff;
        }

        svg polyline {
          stroke: #fff;
        }

        svg polygon {
          stroke: #fff;
        }

        a {
          background-color: #1b5480;
        }
      }
    }

    li {
      list-style: none;
      .home-item {
        margin-bottom: 0;

        &.active,
        &:hover {
          svg,
          svg .a {
            fill: #fff;
          }

          svg path {
            stroke: #fff;
          }

          svg rect {
            stroke: #fff;
          }

          svg circle {
            stroke: #fff;
          }

          svg line {
            stroke: #fff;
          }

          svg polyline {
            stroke: #fff;
          }

          svg polygon {
            stroke: #fff;
          }

          a {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  .sidebar--small {
    .mobile-toggle {
      opacity: 1;
    }
  }

  .mobile-toggle {
    @media (max-width: 768px) {
      position: absolute;
      right: -71px;
      padding: 18px 27px;
      opacity: 0;
      top: 0;

      i {
        font-size: 24px;
      }
    }
  }

  //tabs
  .nav-tabs .nav-link {
    color: $primary;
    background: #f4f4f4;
    border-radius: 6px 6px 0px 0;
    border-bottom: 1px solid;
    text-align: center;
    padding: 0.8rem 1rem;
    white-space: nowrap;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #226666;
    border-color: #226666;
    font-weight: bold;
  }

  .nav-tabs {
    border-bottom: 1px solid #226666;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;

    li.nav-item {
      flex-grow: 1;
    }
  }

  .nav-tabs .nav-item.show .nav-link:after,
  .nav-tabs .nav-link.active:after {
    content: unset;
  }

  //empty state
  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    height: calc(100vh - 270px);

    img {
      width: 240px;

      @media (max-width: 768px) {
        width: 122px;
      }
    }
  }

  //buttons
  // .btn {
  //   border-radius: 8px !important;
  //   padding: 0.625rem 1rem !important;
  // }

  // .btn-primary {
  //   color: #fff;
  //   background-color: $primary;
  //   border-color: $primary;
  // }

  // .btn-outline-primary {
  //   color: $primary;
  //   border-color: $primary;

  //   &:hover {
  //     background-color: $primary;
  //     color: #fff;
  //   }
  // }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: $primary !important;
    color: #fff !important;
  }

  .text-primary {
    color: $primary !important;
  }

  //radio
  .form-check-input[type="radio"] + label:after {
    background: $primary;
  }

  .form-check-input[type="radio"]:checked + label:before {
    border: 1px solid $primary;
  }

  //table
  table {
    border-collapse: separate;
    border-spacing: 0 13px;

    th {
      vertical-align: middle;
      background-color: #f4f5f7;
    }

    &.normal {
      border-collapse: unset;
      border-spacing: unset;
      margin-bottom: 0;

      tr {
        background-color: white;
      }
    }

    tr {
      border-radius: 5px;

      td {
        padding-top: 1rem;
        padding-bottom: 1rem;

        &:first-child {
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }

  //alert

  .alert-success {
    background-color: #b7efc1;
    color: $primary;
    border: 0;
    border-bottom: 1px solid #b7efc1;
  }

  //pagination
  .pagination .page-item.active .page-link {
    background-color: #226666;
    color: #ffffff;
    border-color: #226666;
    border-radius: 4px;
  }

  // home
  .home-body {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(6, auto);
    grid-template-areas:
      "filter"
      "payments"
      "sms"
      "activity"
      "card1"
      "card1";
    grid-gap: 1rem;

    @media (min-width: 830px) {
      margin: 0;
      grid-template-columns: repeat(2, 33%) 30%;
      grid-template-rows: 80px 315px auto auto;
      grid-template-areas:
        "filter filter activity"
        "payments payments activity"
        "sms sms activity"
        "card1 card1 activity"
        ". . activity";
      grid-gap: 1rem;
    }

    .portal-links {
      color: $secondary !important;
      text-decoration: none;
    }
  }

  #filter {
    grid-area: filter;
  }

  #payments {
    grid-area: payments;

    .empty {
      height: 200px;

      @media (max-width: 830px) {
        height: auto;
      }
    }
  }

  #sms {
    grid-area: sms;

    .empty {
      height: 200px;

      @media (max-width: 820px) {
        height: auto;
      }
    }
  }

  #card1 {
    grid-area: card1;
  }

  #activity {
    grid-area: activity;

    //tabs
    .nav-tabs .nav-link {
      color: $primary;
      background: transparent;
      text-align: center;
      padding: 0.8rem 1rem;
      white-space: nowrap;
      border-bottom: none;
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 4px solid $primary;
      font-weight: bold;
    }

    .nav-tabs {
      border: none;
      flex-wrap: nowrap;
      overflow-y: hidden;
      overflow-x: auto;

      li.nav-item {
        flex-grow: 1;
      }
    }

    .nav-tabs .nav-item.show .nav-link:after,
    .nav-tabs .nav-link.active:after {
      content: unset;
    }

    .dashboard-tab-view {
      height: calc(100vh - 310px);
    }
  }

  .payments-body {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr auto;
    grid-template-areas:
      "chart"
      "config"
      "tbl";
    grid-gap: 1rem;

    @media (min-width: 768px) {
      margin: 0;
      grid-template-columns: 70% 30%;
      grid-template-rows: minmax(250px, auto) auto;
      grid-template-areas:
        "chart config"
        "tbl tbl";
      grid-gap: 1rem;
    }
  }

  #chart {
    grid-area: chart;

    .graph {
      height: 185px;
    }
  }

  #configuration {
    grid-area: config;
  }

  #payments-table {
    grid-area: tbl;
  }

  .bg-faded-green {
    background-color: #f2faf8 !important;
  }

  .bg-faded-blue {
    background-color: #f2f7fa !important;
  }

  /*--------------
    Custom Progress Bar
---------------*/
  .devprogressbar li {
    width: 32.33% !important;
    // cursor: pointer;
  }
}
