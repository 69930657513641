.topbar {
  width: 100%;
  position: fixed;
  top: 0;
  height: 60px;
  z-index: 100;
  background-color: #fff;
  border-bottom: 1px solid #DCE1E6;

  .topbar__wrapper {
    position: relative;
    display: flex;
  }
  .topbar__button {
    width: 60px;
    height: 60px;
    display: flex;
    background: transparent;
    border: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &:hover {
      .topbar__button-icon {
        fill: theme-color("primary");
      }
    }
  }
  .topbar__button-icon {
    margin: auto;
    transition: all 0.3s;
    fill: color("slate-gray");
  }

  .topbar__logo {
    width: 100%;
    height: 32px;
    margin: auto 0;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: left;
    background-size: contain;
    display: block;
    background-image: url(../images/hub.png);

    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  .topbar__right {
    position: absolute;
    right: 0;
    display: flex;
    height: 100%;
    margin-right: 15px;
  }

  .topbar__avatar {
    height: 100%;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 0;
    border: none;
    transition: all 0.3s;
    box-shadow: none;
    padding: 0 15px;
    background-color: transparent;
    &:hover,
    &:focus,
    &:active,
    &:focus:active {
      background-color: $navbar-light-active-color;
    }
    &:focus {
      outline: none;
    }
    &:before {
      display: none;
    }
  }

  .topbar__avatar-img,
  .topbar__avatar-name,
  .topbar__avatar-icon {
    margin: auto 0;
  }

  .topbar__avatar-img {
    border-radius: 50%;
    height: 36px;
    width: 36px;
    margin-right: 10px;
  }

  .topbar__avatar-name {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    display: none;
    color: color("slate-gray");
  }

  .topbar__avatar-icon {
    opacity: 0.3;
    margin-left: 8px;
    fill: color("slate-gray");
    height: 18px;
    margin-top: auto;
  }


  .topbar__profile {
    margin-bottom: 0;
    margin-left: 20px;
  }

  .topbar__btn {
    font-size: 18px;
    height: 100%;
    padding: 0 10px;
    cursor: pointer;
    position: relative;
    display: flex;
    border: none;
    background: transparent;
    transition: all 0.3s;
    color: color("slate-gray");

    &:hover {
      background-color: $dropdown-link-hover-bg;
    }
    span {
      margin: auto;
      height: 18px;
    }
    &.topbar__btn--new {
      .topbar__btn-new-label {
        position: absolute;
        right: 9px;
        top: 20px;
        &>div {
          position: relative;
          &:before {
            background-color: rgba(224, 83, 111, 0.2);
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            animation: beforePulse 1.5s infinite;
          }
          &:after {
            height: 7px;
            width: 7px;
            background-color: #e0536f;
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    @keyframes beforePulse {
      from {
        width: 7px;
        height: 7px;
      }
      25% {
        width: 13px;
        height: 13px;
      }
      to {
        width: 7px;
        height: 7px;
      }
    }
  }


  @media screen and (min-width: 480px) {
    .topbar__avatar-name {
      display: block;
    }
    .topbar__search {
      display: flex;
    }
    .topbar__menu {
      width: 100%;
      left: 0 !important;
    }
  }
}
