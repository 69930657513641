.switch {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 31px;

    &.mini{
      width: 31px;
      height: 17px;
    }
  }

  
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .switch.mini{
    .slider:before {
      height: 12px;
      width: 12px;
      bottom: 3px;
    }
  }
  
  
  input:checked + .slider {
    background-color: theme-color(primary);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .switch.mini input:checked + .slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }
  
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }



  //date-picker
  .datepicker-days .switch{
    display: table-cell;
  }


  .datepicker td.active:hover, 
  .datepicker td.active:hover:hover, 
  .datepicker td.active:focus, 
  .datepicker td.active:hover:focus, 
  .datepicker td.active:active, 
  .datepicker td.active:hover:active, 
  .datepicker td.active.active, 
  .datepicker td.active:hover.active, 
  .datepicker td.active.disabled, 
  .datepicker td.active:hover.disabled, 
  .datepicker td.active[disabled], 
  .datepicker td.active:hover[disabled]{
    background-color: #00c7b1 !important;
  }

  .datepicker td span.active:hover, 
  .datepicker td span.active:focus, 
  .datepicker td span.active:active, 
  .datepicker td span.active.active, 
  .datepicker td span.active.disabled, 
  .datepicker td span.active[disabled]{
    background-color: #00c7b1 !important;
  }

  .datepicker td.active, 
  .datepicker td.active:hover{
    color: #ffffff;
    background-color: #00d1ba;
    background-image: -moz-linear-gradient(top, #00d1ba, #00c7b1) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#00c7b1), to(#00c7b1)) !important;
    background-image: -webkit-linear-gradient(top, #00d1ba, #00c7b1) !important;
    background-image: -o-linear-gradient(top, #00d1ba, #00c7b1) !important;
    background-image: linear-gradient(to bottom, #00d1ba, #00c7b1) !important;
    background-repeat: repeat-x ;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0044cc', GradientType=0);
    border-color: #00d1ba #00d1ba #00c7b1 !important;
  }

  .datepicker td span.active{
    color: #ffffff;
    background-color: #00d1ba;
    background-image: -moz-linear-gradient(top, #00d1ba, #00c7b1) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#00c7b1), to(#00c7b1)) !important;
    background-image: -webkit-linear-gradient(top, #00d1ba, #00c7b1) !important;
    background-image: -o-linear-gradient(top, #00d1ba, #00c7b1) !important;
    background-image: linear-gradient(to bottom, #00d1ba, #00c7b1) !important;
    background-repeat: repeat-x ;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0044cc', GradientType=0);
    border-color: #00d1ba #00d1ba #00c7b1 !important;
  }

  .datepicker td span{
    width: 57px !important;
  }

  .datepicker td{
    width: 45px !important;
  }
