@function theme-spacer($key: 0) {
  @return map-get($spacers, $key);
}

@mixin inintial_size($size, $font-size: 1rem) {
  width: $size !important;
  height: $size !important;
  &:before {
    width: $size !important;
    height: $size !important;
    line-height: $size !important;
    font-size: $font-size;
  }
}
