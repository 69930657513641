.table {
  &.nohover {
    tbody tr:hover td {
      background-color: transparent;
    }
  }

  thead {
    border-top: 1px solid #e1ecf4;
    th {
      border-bottom-width: 1px;
      white-space: nowrap;
      font-weight: 600;
      //   background: #f6f8f9;
    }
  }
  tr {
    position: relative;
    th {
      color: #74838f;
    }
    th,
    td {
      border-top: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;

      &:first-child {
        padding-left: 15px;
      }

      &:last-child {
        padding-left: 0;
        padding-right: 15px;
      }
    }

    td {
      vertical-align: middle;
    }

    &[data-target],
    &[data-href] {
      &:hover {
        cursor: pointer;
      }
    }
  }

  th .form-check label,
  td .form-check label {
    padding-left: 0;
  }

  th .form-check label {
    min-height: 20px;
  }

  tbody {
    tr {
      td {
        transition: background-color 0.3s ease-in-out;

        a {
          padding-top: 1rem;
          padding-bottom: 1rem;
          color: $body-color;
          transition: color 0.3s ease-in-out;

          &.link-primary {
            color: theme-color("primary");
            text-decoration: none;
          }
        }
      }
      &:hover {
        td {
          background-color: lighten(color("slate-gray-light"), 8%);

          .fa-ellipsis-h {
            transition: background-color 0.3s ease-in-out;
            background: white;
            -webkit-box-shadow: 0px 1px 5px 0px rgba(214, 210, 214, 1);
            -moz-box-shadow: 0px 1px 5px 0px rgba(214, 210, 214, 1);
            box-shadow: 0px 1px 5px 0px rgba(214, 210, 214, 1);
          }

          a {
            &:not(.link-primary) {
              color: color("slate-gray");
            }
          }
        }
      }
    }
  }

  .card-body & {
    margin-bottom: 0;
  }

  &.table-no-hover {
    tbody {
      tr:hover {
        td {
          background-color: transparent;
        }
      }
    }
  }

  [data-toggle="modal"] {
    &:hover {
      cursor: pointer;
    }
  }
}

td {
  .person,
  .phone-number {
    white-space: nowrap;
    // max-width: 200px;
    display: block;
    margin-bottom: 0;
  }
}

.table-footer {
  border-top: 1px solid $table-border-color;
  padding-top: $card-spacer-x;
  margin-top: $card-spacer-x;

  @include media-breakpoint-up(sm) {
    padding-top: ($card-spacer-x * 1.5625);
    margin-top: ($card-spacer-x * 2);
  }

  .pagination {
    margin-bottom: 0;

    &.pagination-sm {
      .page-link {
        font-size: 1rem;
      }
    }
  }

  .table-info {
    color: color("slate-gray");
    background-color: transparent;
    line-height: 1.5;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;   

    p {
      margin-bottom: 0;
    }
  }
}

.table-responsive {
  // overflow-x: auto !important;

  // @include media-breakpoint-down(sm) {
  //   overflow-x: auto !important;
  // }

   min-height: 300px; 

  table tr td {
    white-space: nowrap;
  }
}

.table-compact {
  tr {
    td:first-child,
    th:first-child {
      padding-left: 0;
    }

    td:last-child,
    th:last-child {
      padding-right: 0;
    }
  }
}

.text-decoration-none {
  text-decoration: none !important;
}
