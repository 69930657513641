$font-family-base: 'Sora',sans-serif;

// $border-radius: 30px;

// Body
$body-color: #000000;
$body-bg: #F7F8FB;

// Grid
$grid-gutter-width: 12px;

// Sidebar
$sidebar-width: 260px;
$sidebar-width-collapsed: 55px;

// Colors
$primary: #01c7b1;
$secondary: #F7F8FA;
$white : #fff;
$danger : #ff0000;

//verification levels
// $bronze:
// $silver:
// $gold:
// $platinum:

$theme-colors: (
    primary: #01c7b1,
    deep-primary: #009f9f,
    faded-gold: #FEE79B,
    faded-silver: #D5DCDF,
    faded-bronze: #E8BD9C
);

$slate-gray-light: #D3E4EF;
$slate-gray: #8195A6;
$badge-bg-blue: #00BAEF;

$colors: (
    slate-gray : $slate-gray,
    slate-gray-light: $slate-gray-light,
    faded-gold: #FEE79B,
    faded-silver: #D5DCDF,
    faded-bronze: #E8BD9C
);

$text-color: $body-color;
$text-muted: $slate-gray;

// Main Navbar
$navbar-fixed-top-padding: 57px;
$navbar-main-outline-border-color: #e6eaed;
$navbar-main-color: $body-color;
$navbar-main-active-color: #F7F8FB;
$navbar-main-toggler-border-color: $slate-gray-light;
$navbar-toggler-size: 1rem;
$navbar-link-padding-x: 1.25rem;

// Tabs
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-color: $primary;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: transparent;
$nav-tabs-border-color: $slate-gray-light;

// Cards
$card-border-color: $slate-gray-light;
$card-border-radius: 0.125rem;
$card-cap-bg: #fff;
$card-input-bg: #F7F8FA;
$card-input-border-color: rgba(126, 143, 164, 0.10);
$card-footer-bg: #FAFCFD;

// Dropdowns
$dropdown-border-radius: 0.125rem;
$dropdown-box-shadow: 0 0 5px rgba(87, 87, 87, .2);

// Btn
$btn-padding-x: 1rem;
$btn-padding-y: 0.625rem;
$btn-border-radius: 8px;

// Forms
$input-border-radius: 8px;
$form-check-input-size: 15px !default;
$form-radio-dot-size: 7px !default;
$form-check-input-border-color: #c0c0c0 !default;
$form-check-input-main-color: $primary !default;
$form-check-input-disabled-color: $slate-gray-light;
$form-check-input-disabled-checked-color: $slate-gray-light;

// Tables
$table-border-color: $slate-gray-light;

// Modal
$modal-content-border-radius: 2px;

// Close 
$close-font-weight: 200;

// Container
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1280px,
  xl: 1630px
);
