.form-group {
  &:last-child {
    margin-bottom: 0;
  }

  label {
    color: color("slate-gray");
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


select.form-control {
  // &:not([size]):not([multiple]) {
    height: calc(2.25rem + 10px);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #f7f8fa url(../images/caret.svg) no-repeat right .75rem center;
    background-size: 11px;
    padding-right: 34px;
  // }

  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $input-color;
    background-color: $input-bg;
  }
}

.form-control {
  color: $input-color;
  background-color: $card-input-bg;
  background-clip: padding-box;
  border-color: #d3e4ef;
  padding: 0.625rem 10px 0.625rem 0.75rem;
  height: unset;

  &::placeholder {
    color: #bfcfd9 !important;
    font-weight: 100;
  }
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: #bbb;
  }

  &:focus {
    box-shadow: none;
    border-color: $input-border-color;
    background-color: $card-input-bg;

    + .btn {
      color: theme-color("primary");
    }
  }

  &::placeholder {
    color: $slate-gray;
  }
}

.input-group-text{
  border: 1px solid #d2e4ef;
}

/*--------------
    Form: Checkboxes
---------------*/
@keyframes hover-color {
  from {
    border-color: $form-check-input-border-color;
  }
  to {
    border-color: $form-check-input-main-color;
  }
}

.form-check {
  padding-left: 0;
}

.form-check-input {
  position: absolute;
  display: none;
}

.form-check-input[disabled] {
  cursor: not-allowed;
}

.form-check-input + label {
  position: relative;
  display: block;
  padding-left: 30px !important;
  cursor: pointer;
  vertical-align: middle;
  min-height: $form-check-input-size;
  &:hover {
    &:before {
      animation-duration: 0.4s;
      animation-fill-mode: both;
      animation-name: hover-color;
    }
  }
  &:before {
    position: absolute;
    top: 2px;
    left: 0;
    display: inline-block;
    width: $form-check-input-size;
    height: $form-check-input-size;
    content: "";
    border: 1px solid $form-check-input-border-color;
  }
  &:after {
    position: absolute;
    display: none;
    content: "";
  }
}

.form-check-input[disabled] + label {
  cursor: not-allowed;
  color: $form-check-input-disabled-color;
  &:hover,
  &:before,
  &:after {
    cursor: not-allowed;
  }
  &:hover {
    &:before {
      border: 1px solid $form-check-input-disabled-color;
      animation-name: none;
    }
  }
  &:before {
    border-color: $form-check-input-disabled-color;
  }
}

.form-check-input:checked + label {
  &:before {
    animation-name: none;
  }
  &:after {
    display: block;
  }
}

.form-check-input[type="checkbox"] + label {
  &:before {
    border-radius: 3px;
  }
  &:after {
    top: 4px;
    left: 5px;
    box-sizing: border-box;
    width: 5px;
    height: 8px;
    transform: rotate(45deg);
    border-width: 2px;
    border-style: solid;
    border-color: #fff;
    border-top: 0;
    border-left: 0;
  }
}

.form-check-input[type="checkbox"]:checked + label {
  &:before {
    border: $form-check-input-main-color;
    background: $form-check-input-main-color;
  }
}

.form-check-input[type="checkbox"]:checked[disabled] + label {
  &:before {
    border: $form-check-input-disabled-checked-color;
    background: $form-check-input-disabled-checked-color;
  }
}

.form-check-input[type="radio"] + label {
  &:before {
    border-radius: 50%;
  }
  &:after {
    top: 6px;
    left: 4px;
    width: $form-radio-dot-size;
    height: $form-radio-dot-size;
    border-radius: 50%;
    background: $form-check-input-main-color;
  }
}

.form-check-input[type="radio"]:checked + label {
  &:before {
    border: 1px solid $form-check-input-main-color;
  }
}

.form-check-input[type="radio"]:checked[disabled] + label {
  &:before {
    border: 1px solid $form-check-input-disabled-checked-color;
  }
  &:after {
    background: $form-check-input-disabled-checked-color;
  }
}

@media(max-width: 1200px) {
  //for forms
  .form-control {
    padding: .25rem 10px .28rem .75rem;
  }
}
