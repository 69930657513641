@font-face {
  font-family: 'Hubtel Awesome';
  src: url('fonts/hubtelicons.eot?n7t8wh');
  src: url('fonts/hubtelicons.eot?n7t8wh#iefix') format('embedded-opentype'), url('fonts/hubtelicons.ttf?n7t8wh') format('truetype'), url('fonts/hubtelicons.woff?n7t8wh') format('woff'), url('fonts/hubtelicons.svg?n7t8wh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], 
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Hubtel Awesome' !important; 
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-no-data .path1:before {
  content: "\e900";
  color: rgb(211, 228, 239);
}

.icon-no-data .path2:before {
  content: "\e901";
  margin-left: -1.8671875em;
  color: rgb(167, 188, 219);
}

.icon-no-data .path3:before {
  content: "\e902";
  margin-left: -1.8671875em;
  color: rgb(234, 240, 244);
}

.icon-no-data .path4:before {
  content: "\e903";
  margin-left: -1.8671875em;
  color: rgb(167, 188, 219);
}

.icon-no-data .path5:before {
  content: "\e904";
  margin-left: -1.8671875em;
  color: rgb(126, 143, 164);
}

.icon-no-data .path6:before {
  content: "\e905";
  margin-left: -1.8671875em;
  color: rgb(255, 255, 255);
}

.icon-no-data .path7:before {
  content: "\e906";
  margin-left: -1.8671875em;
  color: rgb(167, 188, 219);
}

.icon-no-data .path8:before {
  content: "\e907";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-data .path9:before {
  content: "\e908";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-data .path10:before {
  content: "\e909";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-data .path11:before {
  content: "\e90a";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-data .path12:before {
  content: "\e90b";
  margin-left: -1.8671875em;
  color: rgb(255, 255, 255);
}

.icon-no-data .path13:before {
  content: "\e90c";
  margin-left: -1.8671875em;
  color: rgb(167, 188, 219);
}

.icon-no-data .path14:before {
  content: "\e90d";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-data .path15:before {
  content: "\e90e";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-data .path16:before {
  content: "\e90f";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-data .path17:before {
  content: "\e910";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-search-data .path1:before {
  content: "\e911";
  color: rgb(211, 228, 239);
}

.icon-no-search-data .path2:before {
  content: "\e912";
  margin-left: -1.8671875em;
  color: rgb(167, 188, 219);
}

.icon-no-search-data .path3:before {
  content: "\e913";
  margin-left: -1.8671875em;
  color: rgb(234, 240, 244);
}

.icon-no-search-data .path4:before {
  content: "\e914";
  margin-left: -1.8671875em;
  color: rgb(167, 188, 219);
}

.icon-no-search-data .path5:before {
  content: "\e915";
  margin-left: -1.8671875em;
  color: rgb(126, 143, 164);
}

.icon-no-search-data .path6:before {
  content: "\e916";
  margin-left: -1.8671875em;
  color: rgb(255, 255, 255);
}

.icon-no-search-data .path7:before {
  content: "\e917";
  margin-left: -1.8671875em;
  color: rgb(167, 188, 219);
}

.icon-no-search-data .path8:before {
  content: "\e918";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-search-data .path9:before {
  content: "\e919";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-search-data .path10:before {
  content: "\e91a";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-search-data .path11:before {
  content: "\e91b";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-search-data .path12:before {
  content: "\e91c";
  margin-left: -1.8671875em;
  color: rgb(255, 255, 255);
}

.icon-no-search-data .path13:before {
  content: "\e91d";
  margin-left: -1.8671875em;
  color: rgb(167, 188, 219);
}

.icon-no-search-data .path14:before {
  content: "\e91e";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-search-data .path15:before {
  content: "\e91f";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-search-data .path16:before {
  content: "\e920";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

.icon-no-search-data .path17:before {
  content: "\e921";
  margin-left: -1.8671875em;
  color: rgb(211, 228, 239);
}

